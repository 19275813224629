interface Data {
  baseLayout: any[];
  features: Record<string, any>;
  homeOrder: any[];
  views: Record<string, any>;
  stylesheet: Record<string, any>;
  tagger: any;
}

export const IdentifyActiveFeaturesViews = (data: Data): string[] => {
  const featuresFromViews: string[] = []; // array of features that are called from views
  const featuresFromMenus: string[] = []; // array of features that are called from menus

  //using the activeViewKeys
  //identify features being called from each view
  const extractFeaturesFromViews = (view: any) => {
    if (!view) return;
    if (view.feature) featuresFromViews.push(view.feature);
    if (view.config) {
      if (view.config.feature) featuresFromViews.push(view.config.feature);
      if (view.config.items) {
        view.config.items.forEach((item: any) => {
          if (typeof item === "string") {
            featuresFromViews.push(item);
          } else if (item.feature) {
            featuresFromViews.push(item.feature);
          }
        });
      }
      if (view.config.categories) {
        view.config.categories.forEach((subcategories: any) => {
          if (subcategories.nestedSubcategories) {
            subcategories.nestedSubcategories.forEach((item: any) => {
              if (item.feature) featuresFromViews.push(item.feature);
            });
          }
        });
      }
    }
    if (view.pages)
      view.pages.forEach((item: any) => featuresFromViews.push(item));
    if (view.buttons)
      view.buttons.forEach((item: any) => featuresFromViews.push(item.feature));
    if (view.extras?.feature) featuresFromViews.push(view.extras.feature);
  };

  //featuresFromViews identify features being called from submenu and megamenu
  const extractFeaturesFromMenus = (feature: any) => {
    if (feature) {
      if (feature.dropdown) {
        feature.dropdown.forEach((item: any) => {
          if (typeof item === "string") {
            // Directly add string items
            featuresFromMenus.push(item);
          }
          if (typeof item === "object") {
            item.items.forEach((subitem: any) => {
              featuresFromMenus.push(subitem);
            });
          }
        });
      }
    }
  };

  //using the homeOrder identify the active views, active views are those listed in the homeOrder
  const activeViewKeys =
    Array.isArray(data.homeOrder) &&
    typeof data.views === "object" &&
    data.views !== null
      ? data.homeOrder.filter((view: string) => view in data.views)
      : [];

  // Define viewsObject
  const viewsObject = data.views;

  // this will pull the features that are called from navbar and footer
  if (viewsObject.navbar?.config?.items)
    viewsObject.navbar.config.items.forEach((item: any) =>
      featuresFromViews.push(item)
    );
  if (viewsObject.footer?.config?.item2?.options)
    viewsObject.footer.config.item2.options.forEach((item: any) =>
      featuresFromViews.push(item.feature)
    );

  activeViewKeys.forEach((viewKey: any) =>
    extractFeaturesFromViews(viewsObject[viewKey])
  );

  // Define featuresObject
  const featuresObject = data.features;

  // take the featuresFromViews and identify any submenus and megamenus
  const siteFeatures = featuresFromViews.filter(
    (view: string) => featuresObject[view]
  );
  siteFeatures.forEach((featureKey: any) => {
    extractFeaturesFromMenus(featuresObject[featureKey]);
  });

  // get all the active features from the views and other features
  const uniqueFeatures = Array.from(
    new Set([...featuresFromViews, ...featuresFromMenus])
  );
  const sortedFeatures = uniqueFeatures.sort((a, b) => a.localeCompare(b));
  const featuresForSiteMap = sortedFeatures.filter(
    (item) => !/submenu|subMenu|MegaMenu|https?:/i.test(item)
  );

  return featuresForSiteMap;
};
