import { MobileNav } from "./MobileNav/MobileNav";
import { DesktopNav } from "./DesktopNav/DesktopNav";

export const Navbar = ({
  manifestData,
}: Record<string, unknown>): JSX.Element => {
  return (
    <div id="OCVNav">
      <DesktopNav
        key={"DesktopNav"}
        manifestData={manifestData}
      />
      <MobileNav
        key={"MobileNav"}
        manifestData={manifestData}
      />
      <div
        key={"DummyHeader"}
        id="OCVDummy-header"
        style={{ marginTop: "100px" }}
      ></div>
    </div>
  );
};
